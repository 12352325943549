<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link tag="a" to="/" class="text-dark">
        <i class="fas fa-home h2"></i>
      </router-link>
    </div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
              <div class="bg-primary">
                <div class="text-primary text-center p-4">
                  <h5 class="text-white font-size-20">Token de recuperação</h5>
                  <p class="text-white-50">
                    Olá Diana, insira abaixo o token para recuperação de senha,
                    enviado para o e-mail
                    <strong>diana.mercer@email.com</strong>
                  </p>
                  <router-link tag="a" to="/" class="logo logo-admin">
                    <img
                      src="@/assets/images/logo-sm.png"
                      height="24"
                      alt="logo"
                    />
                  </router-link>
                </div>
              </div>

              <div class="card-body p-4">
                <div class="p-3">
                  <form @submit.prevent="onSubmit" class="form-horizontal mt-4">
                    <div class="form-group">
                      <label for="token">Token</label>
                      <input
                        type="text"
                        class="form-control"
                        id="token"
                        placeholder="Insira seu token"
                        v-model="token"
                      />
                    </div>

                    <div class="form-group row mb-0">
                      <div class="col-12 text-right">
                        <button class="btn btn-primary w-md" type="submit">
                          Validar token
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="mt-4 text-center">
              <p class="mb-0">
                ©
                {{ new Date().getFullYear() }}
                QuestõesPRO.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- end container -->
    </div>
  </div>
</template>

<script>
// import * as api from "@/api";

import appConfig from "@/app.config";

/**
 * Lock-screen-1 component
 */
export default {
  page: {
    title: "Token",
    meta: [{ name: "description", content: appConfig.description }],
  },

  data() {
    return {
      token: "",
    };
  },

  methods: {
    onSubmit() {
      if (!this.token) {
        return this.$swal(
          "Opa",
          "Parece que você não inseriu o token de validação!",
          "warning"
        );
      }

      if (this.token === "1234") {
        this.$swal("Sucesso", "Token validado com sucesso!", "success");
        return this.$router.push({ name: "newPassword" });
      } else {
        return this.$swal("Opa", "Token inválido!", "error");
      }

      // api
      //   .validateToken(this.token)
      //   .then(() => {
      //     this.$swal("Sucesso", "Token validado com sucesso!", "success");
      //     return this.$router.push({ name: "newPassword" });
      //   })
      //   .catch(err => {
      //     if (err) {
      //       return this.$swal("Opa", "Token inválido!", "error");
      //     } else {
      //       this.$swal.stopLoading();
      //       this.$swal.close();
      //     }
      //   });

      // teste
    },
  },
};
</script>
